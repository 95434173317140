import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import PortfolioBanner from '../components/portfolio-banner/PortfolioBanner'
import PortfolioHeader from '../components/portfolio-header/PortfolioHeader'
import NewsFilters from '../components/news-filters/NewsFilters'
import NewsItem from '../components/news/NewsItem'
import Pagination from '../components/pagination/Pagination'
import SEO from '../components/seo'

class NewsTemplate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [],
      filteredItems: [],
      category: '',
      time: 'asc',
      oldSort: 'asc',
      visibleItems: 7,
      actualPage: 1,
    }
  }
  setFooterMargin() {
    document.querySelector('footer').style.marginTop = '-120px'
  }
  changeCategory(value) {
    this.setState({ category: value })
  }
  changeTime(value) {
    this.setState({ time: value })
  }
  setPostsToState() {
    this.setState({
      items: this.props.data.allWordpressPost.edges,
      filteredItems: this.props.data.allWordpressPost.edges,
    })
  }

  sortItems() {
    if (this.state.time === 'desc' && this.state.oldSort === 'asc') {
      this.setState({
        filteredItems: this.state.filteredItems.reverse(),
        items: this.state.items.reverse(),
        oldSort: 'desc',
      })
    } else if (this.state.time === 'asc' && this.state.oldSort === 'desc') {
      this.setState({
        filteredItems: this.state.filteredItems.reverse(),
        items: this.state.items.reverse(),
        oldSort: 'asc',
      })
    } else {
      return false
    }
  }

  async search() {
    let tempNews = this.state.items
    if (this.state.category == '' && this.state.time == '') {
      await this.setPostsToState()
    } else {
      await this.setState({ filteredItems: [] })
      await tempNews
        .filter(item =>
          this.state.category != ''
            ? item.node.categories[0].slug === this.state.category
            : item
        )
        .map(item => {
          this.setState({ filteredItems: [...this.state.filteredItems, item] })
        })
      await this.sortItems()
    }
  }
  componentDidMount() {
    this.setFooterMargin()
    this.setPostsToState()
  }
  changePage(index) {
    this.setState({ actualPage: index })
  }
  render() {
    const currentPage = this.props.data.wordpressPage
    const Items = data => {
      let n = 0
      let maxItems = data.data.visibleItems
      let actualPage = data.data.actualPage
      let itemsLength = data.data.filteredItems.length
      console.log(Math.round(maxItems * actualPage - 1))
      let items = data.data.filteredItems.map((item, index) => {
        if (item.node.slug !== 'test') {
          if (
            n < maxItems &&
            index <= Math.round(maxItems * actualPage - 1) &&
            index >= Math.round(maxItems * actualPage - 1 - maxItems + 1)
          ) {
            n++
            console.log('index: ' + index + ' ' + item.node.title)
            return <NewsItem key={index} data={item.node} />
          }
        }
      })
      return items
    }
    return (
      <Layout>
        <SEO
          title={
            currentPage.title +
            ' | Kuchnie na wymiar Śląsk, Małopolska | Meble kuchenne Kraków'
          }
        />
        <PortfolioBanner data={currentPage.featured_media} />
        <div className="take-up">
          <PortfolioHeader
            title={currentPage.title}
            city={currentPage.content}
          />
          <NewsFilters
            categories={this.props.data.allWordpressCategory.edges}
            changeCategory={this.changeCategory.bind(this)}
            changeTime={this.changeTime.bind(this)}
            search={this.search.bind(this)}
          />
          <div class="container last-posts">
            <div class="row">
              <Items data={this.state} />
            </div>
          </div>
          {this.state.filteredItems.length > this.state.visibleItems && (
            <Pagination
              newsLength={this.state.filteredItems.length}
              changePage={this.changePage.bind(this)}
              maxShowItems={this.state.visibleItems}
            />
          )}
        </div>
      </Layout>
    )
  }
}

export default NewsTemplate

export const pageQuery = graphql`
  query($id: String) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      featured_media {
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 1080, quality: 95) {
              srcSet
            }
          }
        }
      }
    }
    allWordpressPost(filter: { status: { eq: "publish" } }) {
      edges {
        node {
          title
          excerpt
          slug
          content
          date(formatString: "MM/DD/YYYY")
          categories {
            name
            slug
          }
        }
      }
    }
    allWordpressCategory {
      edges {
        node {
          name
          slug
        }
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`
